@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
        font-size: 2em;
        margin: 0.67em 0;
    }

    h2 {
        font-size: 1.5em;
        margin: 0.83em 0;
        font-weight: bold;
    }

    a {
        @apply text-blue-500;
        text-decoration: underline;
    }

    a:hover {
        @apply text-blue-700;
    }

    button {
        line-height: 1;
    }
}
