.toastBody
  padding: 0

.toastTitle
  font-weight: 600
  font-size: 1em

.toastDescription
  font-weight: 400
  font-size: 0.9em

.hint
  font-weight: 400
  font-size: 0.6em
